<template>
  <div>
    <div class="mt-15 px-8" v-if="isLoad">
      <v-row class="px-15">
        <v-col cols="12" v-for="i in 5" :key="i" class="text-left">
          <v-skeleton-loader
            type="paragraph"
            class="mt-3"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div class="white grid justify-items-center px-0 py-10 text-left" v-else>
      <v-col cols="10" sm="6" class="pa-0 mt-10 pt-10" v-if="items.length> 0">
        <div class="font-bold text-2xl sm:text-4xl mt-5 text-capitalize">{{ bahasa ? 'Hukum' : 'Legals' }}</div>
        <v-skeleton-loader
          v-if="isLoad"
          type="paragraph"
          class="mt-3"
        ></v-skeleton-loader>
        <div v-else-if="items.length > 0">
          <div class="mt-5 mb-10">
            <div class="font-bold text-base sm:text-xl my-5 text-left" v-if="bahasa">Dasar hukum BPDLH</div>
            <div class="font-bold text-base sm:text-xl my-5 text-left" v-else>Legal basis of BPDLH</div>
            <div class="font-lora text-base mb-3 cursor-pointer" v-for="(item, index) in items" :key="index" @click="downloadFile(item)">
              <u>{{ item.name }}</u>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="11" class="pa-0 mt-10 py-10" v-else>
        <v-row>
          <v-col cols="12" sm="6">
            <img src="@/assets/images/405.png" width="100%"/>
          </v-col>
          <v-col cols="12" sm="6" class="text-black flex flex-wrap content-center">
            <v-col cols="12" class="text-3xl font-bold pl-0">{{bahasa? 'Tidak Ada Data Hukum yang Tersedia' : 'No Law Data Available'}}</v-col>
            <v-btn class="btn-primary mt-8" :to="{name: 'home'}">
              {{bahasa ? 'kembali ke beranda' : 'back to home'}}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
	props: {
		bahasa: {
			type: Boolean,
			default: true
		},
		id: {
			type: String,
			require: false
		}
	},
	data() {
		return {
			isLoad: false,
			items: [],
			baseUrl: process.env.VUE_APP_BASE_URL,
			baseUrlImage: process.env.VUE_APP_BASE_ASSET,
			lastUpdated: ''
		}
	},
	computed: {
		language() {
      return this.bahasa ? 'ID' : 'ENG';
    }
	},
	watch: {
		language() {
      this.getData();
    },
		page() {
			this.getData();
		}
	},
	mounted() {
    this.getData();
	},
	methods: {
		async getData() {
			this.isLoad = true;
			let param = {
				lang: this.language
			}
      await axios.get(this.baseUrl + '/law', {params: param}).then(response => {
        this.items = response.data.data.data;
      })
      .catch(error => {
				Swal.fire('Failed!', error.response.data.message, 'error');
      })
      .finally(() => this.isLoad = false);
		},
    downloadFile(item) {
      let url = item.url ? item.url : this.baseUrlImage + item.image.file_path;
			window.open(url, '_blank');
    }
	}
}
</script>